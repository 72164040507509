import styled from 'styled-components';
// import { darken } from 'polished';
import theme from '../../config/theme';

export const PostsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  color: ${theme.colors.black.base};
  border-radius: ${theme.borderRadius.default};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: relative;
`;

// export const Line = styled.div`
//   width: 100%;
//   height: 2px;
//   background: rgba(0,0,0,.3);
//   margin: 1rem 0;
// `;
export const Line = styled.hr`
  margin: 25px 0;
  height: 1px;
  border: 0;
  background: #333;
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(white),
    to(#333),
    color-stop(50%, #333)
  );
  @media (max-width: 900px) {
    display: none;
  }
`;

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;

export const Container = styled.div`
  padding: 2rem;
`;

export const InfoText = styled.div`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fontFamily.heading};
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.colors.black.light};
`;
